<template>
  <page-header-wrapper>
    <a-card :bordered="false">
      <!-- 条件搜索 -->
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <a-col :md="8" :sm="24">
              <a-form-item label="车辆编号" prop="vehicleId">
                <a-input v-model="queryParam.vehicleId" placeholder="请输入车辆编号" allow-clear />
              </a-form-item>
            </a-col>
            <a-col :md="8" :sm="24">
              <a-form-item label="数据类型" prop="dataType">
                <a-select placeholder="请选择数据类型" style="width: 100%" v-model="queryParam.dataType" allow-clear>
                  <a-select-option v-for="(d, index) in deviceDataTypeOptions" :key="index" :value="d.dictValue">{{
                    d.dictLabel
                  }}</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <template v-if="advanced">
              <a-col :md="8" :sm="24">
                <a-form-item label="设备名称" prop="deviceName">
                  <a-input v-model="queryParam.deviceName" placeholder="请输入设备名称" allow-clear />
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="设备卡号" prop="deviceCard">
                  <a-input v-model="queryParam.deviceCard" placeholder="请输入设备卡号" allow-clear />
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="IMEI" prop="imei">
                  <a-input v-model="queryParam.imei" placeholder="请输入IMEI" allow-clear />
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="是否有效" prop="status">
                  <a-select placeholder="请选择是否有效" v-model="queryParam.status">
                    <a-select-option v-for="d in statusOptions" :key="d.dictValue" :value="d.dictValue">{{
                      d.dictLabel
                    }}</a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
            </template>
            <a-col :md="(!advanced && 8) || 24" :sm="24">
              <span
                class="table-page-search-submitButtons"
                :style="(advanced && { float: 'right', overflow: 'hidden' }) || {}"
              >
                <a-button type="primary" @click="handleQuery"><a-icon type="search" />查询</a-button>
                <a-button style="margin-left: 8px" @click="resetQuery"><a-icon type="redo" />重置</a-button>
                <a @click="toggleAdvanced" style="margin-left: 8px">
                  {{ advanced ? '收起' : '展开' }}
                  <a-icon :type="advanced ? 'up' : 'down'" />
                </a>
              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <!-- 操作 -->
      <a-toolbar :loading="loading" refresh @refreshQuery="getList" tid="1">
        <div class="table-operations">
          <a-button type="primary" @click="$refs.createForm.handleAdd()" v-hasPermi="['tenant:iot:raDevice:add']">
            <a-icon type="plus" />新增
          </a-button>
          <a-button
            type="primary"
            :disabled="single"
            @click="$refs.createForm.handleUpdate(selectedRows ? selectedRows[0] : undefined, ids)"
            v-hasPermi="['tenant:iot:raDevice:edit']"
          >
            <a-icon type="edit" />修改
          </a-button>
          <a-button
            type="danger"
            :disabled="multiple"
            @click="handleDelete"
            v-hasPermi="['tenant:iot:raDevice:remove']"
          >
            <a-icon type="delete" />删除
          </a-button>
          <a-button type="primary" @click="handleExport" v-hasPermi="['tenant:iot:raDevice:export']">
            <a-icon type="download" />导出
          </a-button>
          <!-- <a-button
            type="dashed"
            shape="circle"
            :loading="loading"
            :style="{ float: 'right' }"
            icon="reload"
            @click="getList"
          /> -->
        </div>
      </a-toolbar>

      <!-- 增加修改 -->
      <create-form
        ref="createForm"
        :statusOptions="statusOptions"
        :deviceTypeOptions="deviceTypeOptions"
        :deviceDataTypeOptions="deviceDataTypeOptions"
        @ok="getList"
      />
      <!-- 数据展示 -->
      <a-table
        :loading="loading"
        :size="tableSize"
        rowKey="id"
        tid="1"
        :columns="columns"
        :data-source="list"
        :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
        :pagination="false"
      >
        <span slot="effectiveTime" slot-scope="text, record" :title="parseTime(record.effectiveTime)">
          {{ parseTime(record.effectiveTime) }}
        </span>
        <span slot="status" slot-scope="text, record" :title="statusFormat(record.status)">
          {{ statusFormat(record.status) }}
        </span>
        <span slot="transcodeStatus" slot-scope="text, record" :title="statusFormat(record.transcodeStatus)">
          {{ statusFormat(record.transcodeStatus) }}
        </span>
        <span slot="uploadDataStatus" slot-scope="text, record" :title="statusFormat(record.uploadDataStatus)">
          {{ statusFormat(record.uploadDataStatus) }}
        </span>
        <span slot="dataType" slot-scope="text, record" :title="dataTypeFormat(record.dataType)">
          {{ dataTypeFormat(record.dataType) }}
        </span>
        <span slot="deviceType" slot-scope="text, record" :title="deviceTypeFormat(record.deviceType)">
          {{ deviceTypeFormat(record.deviceType) }}
        </span>
        <span slot="operation" slot-scope="text, record">
          <a-divider type="vertical" v-hasPermi="['tenant:iot:raDevice:edit']" />
          <a @click="$refs.createForm.handleUpdate(record, undefined)" v-hasPermi="['tenant:iot:raDevice:edit']">
            <a-icon type="edit" />修改
          </a>
          <a-divider type="vertical" v-hasPermi="['tenant:iot:raDevice:remove']" />
          <a @click="handleDelete(record)" v-hasPermi="['tenant:iot:raDevice:remove']">
            <a-icon type="delete" />删除
          </a>
        </span>
      </a-table>
      <!-- 分页 -->
      <a-pagination
        class="ant-table-pagination"
        show-size-changer
        show-quick-jumper
        :current="queryParam.pageNum"
        :total="total"
        :page-size="queryParam.pageSize"
        :showTotal="(total) => `共 ${total} 条`"
        @showSizeChange="onShowSizeChange"
        @change="changeSize"
      />
    </a-card>
  </page-header-wrapper>
</template>

<script>
import { listRaDevice, delRaDevice, exportRaDevice } from '@/api/iot/raDevice'
import CreateForm from './modules/CreateForm'

export default {
  name: 'RaDevice',
  components: {
    CreateForm
  },
  data() {
    return {
      list: [],
      selectedRowKeys: [],
      selectedRows: [],
      // 高级搜索 展开/关闭
      advanced: false,
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      ids: [],
      loading: false,
      total: 0,
      deviceDataTypeOptions: [],
      // 查询参数
      queryParam: {
        vehicleId: null,
        dataType: undefined,
        deviceName: null,
        deviceCard: null,
        imei: null,
        status: undefined,
        pageNum: 1,
        pageSize: 10
      },
      columns: [
        {
          title: 'ID',
          dataIndex: 'id',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '车辆编号',
          dataIndex: 'vehicleId',
          ellipsis: true,
          customRender: (text, record, index) => {
            return record.vehicleId || '--'
          },
          align: 'center'
        },
        {
          title: '车牌号',
          dataIndex: 'plateNum',
          ellipsis: true,
          customRender: (text, record, index) => {
            return record.plateNum || '--'
          },
          align: 'center'
        },
        {
          title: '设备类型',
          dataIndex: 'deviceType',
          scopedSlots: { customRender: 'deviceType' },
          ellipsis: true,
          align: 'center'
        },
        // {
        //   title: '数据类型',
        //   dataIndex: 'dataType',
        //   scopedSlots: { customRender: 'dataType' },
        //   ellipsis: true,
        //   align: 'center'
        // },
        {
          title: '设备名称',
          dataIndex: 'deviceName',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '设备卡号',
          dataIndex: 'deviceCard',
          ellipsis: true,
          align: 'center'
        },
        {
          title: 'IMEI',
          dataIndex: 'imei',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '人员名称',
          dataIndex: 'employeeName',
          ellipsis: true,
          customRender: (text, record, index) => {
            return record.employeeName || '--'
          },
          align: 'center'
        },
        // {
        //   title: '是否上传数据',
        //   dataIndex: 'uploadDataStatus',
        //   scopedSlots: { customRender: 'uploadDataStatus' },
        //   ellipsis: true,
        //   align: 'center'
        // },
        {
          title: '设备编码',
          dataIndex: 'originEquipmentCode',
          ellipsis: true,
          customRender: (text, record, index) => {
            return record.originEquipmentCode || '--'
          },
          align: 'center'
        },
        // {
        //   title: '是否转码',
        //   dataIndex: 'transcodeStatus',
        //   scopedSlots: { customRender: 'transcodeStatus' },
        //   ellipsis: true,
        //   align: 'center'
        // },
        // {
        //   title: '使用年限(年)',
        //   dataIndex: 'serviceLife',
        //   ellipsis: true,
        //   align: 'center'
        // },
        // {
        //   title: '生产厂商',
        //   dataIndex: 'manufacturer',
        //   ellipsis: true,
        //   align: 'center'
        // },
        // {
        //   title: '厂商联系人',
        //   dataIndex: 'manufacturerContact',
        //   ellipsis: true,
        //   align: 'center'
        // },
        // {
        //   title: '联系电话',
        //   dataIndex: 'contactPhone',
        //   ellipsis: true,
        //   align: 'center'
        // },
        {
          title: '有效时间',
          dataIndex: 'effectiveTime',
          scopedSlots: { customRender: 'effectiveTime' },
          ellipsis: true,
          customRender: (text, record, index) => {
            return record.effectiveTime || '--'
          },
          align: 'center'
        },
        // {
        //   title: '固件类型 如：一体机、科隆; 字典设置',
        //   dataIndex: 'firmwareType',
        //   ellipsis: true,
        //   align: 'center'
        // },
        {
          title: '是否有效',
          dataIndex: 'status',
          scopedSlots: { customRender: 'status' },
          ellipsis: true,
          align: 'center'
        },
        {
          title: '操作',
          dataIndex: 'operation',
          width: '15%',
          scopedSlots: { customRender: 'operation' },
          align: 'center'
        }
      ],
      statusOptions: [],
      deviceTypeOptions: []
    }
  },
  filters: {},
  created() {
    this.getData()
    this.getList()
  },
  computed: {},
  watch: {},
  methods: {
    async getData() {
      // 由于列表需要字典数据，所以要先获取到字典数据，再获取列表
      await this.getDictData()
    },
    async getDictData() {
      this.loading = true
      await this.getDicts('iot_device_data_type').then((response) => {
        this.deviceDataTypeOptions = response.data
      })
      await this.getDicts('iot_device_status').then((response) => {
        this.statusOptions = response.data
      })
      await this.getDicts('iot_device_type').then((response) => {
        this.deviceTypeOptions = response.data
      })
      this.loading = false
    },
    /** 查询设备 列表 */
    getList() {
      this.loading = true
      listRaDevice(this.queryParam).then((response) => {
        this.list = response.rows
        this.total = response.total
        this.loading = false
      })
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParam.pageNum = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.queryParam = {
        vehicleId: undefined,
        dataType: undefined,
        deviceName: undefined,
        deviceCard: undefined,
        imei: undefined,
        pageNum: 1,
        pageSize: 10
      }
      this.handleQuery()
    },
    onShowSizeChange(current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    changeSize(current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.ids = this.selectedRows.map((item) => item.id)
      this.single = selectedRowKeys.length !== 1
      this.multiple = !selectedRowKeys.length
    },
    toggleAdvanced() {
      this.advanced = !this.advanced
    },
    /** 删除按钮操作 */
    handleDelete(row) {
      var that = this
      const ids = row.id || this.ids
      this.$confirm({
        title: '确认删除所选中数据?',
        content: '当前选中编号为' + ids + '的数据',
        onOk() {
          return delRaDevice(ids).then(() => {
            that.onSelectChange([], [])
            that.getList()
            that.$message.success('删除成功', 3)
          })
        },
        onCancel() {}
      })
    },
    /** 导出按钮操作 */
    handleExport() {
      var that = this
      this.$confirm({
        title: '是否确认导出?',
        content: '此操作将导出当前条件下所有数据而非选中数据',
        onOk() {
          return exportRaDevice(that.queryParam).then((response) => {
            that.download(response.msg)
            that.$message.success('导出成功', 3)
          })
        },
        onCancel() {}
      })
    },
    // 是否删除 0否1是字典翻译
    statusFormat(status) {
      return this.selectDictLabel(this.statusOptions, status)
    },
    dataTypeFormat(dataType) {
      return this.selectDictLabel(this.deviceDataTypeOptions, dataType)
    },
    deviceTypeFormat(deviceType) {
      return this.selectDictLabel(this.deviceTypeOptions, deviceType)
    }
  }
}
</script>
